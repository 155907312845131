import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "content" ];
  static classes = [ "isOpen" ];

  connect() {
    useClickOutside(this);
    this.parentTr = this.element.closest('tr');
  }
  
  openContent() {
    this.contentTarget.classList.add(this.isOpenClass);
    this.parentTr.classList.add('is-selected');
  }
  
  clickOutside() {
    this.contentTarget.classList.remove(this.isOpenClass);
    this.parentTr.classList.remove('is-selected');
  }
};