import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import './turbolinks_utils';
import './cookieconsent';
import './stylesheets/application.scss';
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';

Rails.start();
Turbolinks.start();

window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));