import { Controller } from "@hotwired/stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  static values = {
    url: String,
    requestType: { type: String, default: "POST" },
    dataType: { type: String, default: "script" }
  }

  triggerRequest (event) {
    Rails.ajax({
      url: this.urlValue,
      type: this.requestTypeValue,
      dataType: this.dataTypeValue,
      success: function(data) {},
      error: function(data) {}
    })
  }

  connect() {
  }

  disconnect() {
  }
}