import { Controller } from "@hotwired/stimulus";

const FLASH_AUTO_DISMISS_DELAY = 8000;

export default class extends Controller {
  connect() {
    this.delayFadeFlash();
  } 

  makeInvisible($el) {
    $el.classList.add('is-invisible');
  }

  delayFadeFlash() {   
    const callback = () => {
      if (this.element.parentElement) {
        this.makeInvisible(this.element.parentElement);
      }
    };
    setTimeout(()=>{
      callback();
    }, FLASH_AUTO_DISMISS_DELAY);
  }
}