import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "input", "label" ]
  connect() {
    this.inputTarget.addEventListener('change', this.handleChange.bind(this));
  };

  handleChange() {
    const filesLength = this.inputTarget.files.length;
    let plural = filesLength >= 2 ? "s" : "";
    this.labelTarget.innerHTML = JSON.stringify(filesLength) + " fichier" + plural + " sélectionné" + plural;  
  }

  disconnect() {
    this.inputTarget.removeEventListener('change', this.handleChange.bind(this));
  }
}