
function initializeGoogleAnalytics() {
  if(window.secrets.gaId) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', window.secrets.gaId);
  }
}

export { initializeGoogleAnalytics };
