import { Controller } from "@hotwired/stimulus";
import Rails from 'rails-ujs';
import 'moment';
import Pikaday from 'pikaday';
import 'pikaday/css/pikaday.css';

const PikadayDefaultConfig = {
  format: 'DD/MM/YYYY',
  firstDay: 1,
  i18n: {
    previousMonth: 'Mois précédent',
    nextMonth: 'Mois suivant',
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
  },
};

export default class extends Controller {
  static values = { submitFormOnChange: { type: Boolean, default: false } }

  connect() {
    const config = Object.assign({}, PikadayDefaultConfig, { field: this.element })
    if (this.submitFormOnChangeValue) {
      config.onSelect = () => {
        this.submitForm();
      }
      this.element.addEventListener('change', (e) => {
        if (e.target.value == "") {
          this.submitForm();
        }
      });  
    }
    this.picker = new Pikaday(config);
  }

  submitForm() {
    Rails.fire(this.element.closest('form'), 'submit');
  }

  disconnect() {
    this.picker.destroy();
  }
}