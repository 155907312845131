import { Controller } from "@hotwired/stimulus";
import '../redactor/redactor.js';
import '../redactor/redactor.css';
import '../redactor/_langs/fr';

const redactorConfig = {
  default: {
    lang: 'fr',
    formatting: ['p', 'blockquote', 'h3', 'h4', 'h5', 'h6'],
    buttons: ['redo', 'undo', 'format', 'bold', 'italic', 'lists', 'link']
  },
  minimal: {
    lang: 'fr',
    buttons: ['redo', 'undo', 'bold', 'link'],
    minHeight: '120px'
  }
}

export default class extends Controller {
  static targets = [ 'input' ];
  static values = { theme: String, default: 'default' };

  connect() {
    $R(this.inputTarget, redactorConfig[this.themeValue]);
  }

  disconnect() {
    $R(this.inputTarget, 'destroy');
  }
}