import { Controller } from "@hotwired/stimulus";
import { getQueryStringParameters } from '../../shared/getQueryStringParameters';

export default class extends Controller {
  static targets = ["trainingSelect", "trainersSelect"];

  connect() {
    if (this.hasTrainingSelectTarget && this.hasTrainersSelectTarget) {
      $(this.trainingSelectTarget).on('change', (ev) => {
        $.ajax({
          type: 'GET',
          dataType: 'script',
          url: `/back_office/training_sessions/refresh_trainers?training_id=${ev.currentTarget.value}`,
        });
      });
  
      const urlParams = getQueryStringParameters();
      if (urlParams.training_id){
        $('#training_session_training_id').val(urlParams.training_id).trigger('change');
      }
    }
  }

  disconnect() {
    if (this.hasTrainingSelectTarget) {
      $(this.trainingSelectTarget).off('change');
    }
  }
}