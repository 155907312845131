import { Controller } from "@hotwired/stimulus";
import 'rateyo/src/jquery.rateyo.css';
import 'rateyo/src/jquery.rateyo.js';

export default class extends Controller {
  static values = { 
    normalFill: { type: String, default: '#bbbbbb' },
    ratedFill: { type: String, default: '#3D7A9C' },
    spacing: { type: String, default: '0px' },
    readOnly: { type: Boolean, default: false },
    rating: { type: String, default: '0' }
  }

  static targets = [ "elem", "input" ];

  connect() {
    if (this.hasElemTarget) {
      this.$element = $(this.elemTarget);  
    } else {
      this.$element = $(this.element);
    }
    this.$rateYo = this.$element.rateYo({
      halfStar: true,
      normalFill: this.normalFillValue,
      ratedFill: this.ratedFillValue,
      spacing: this.spacingValue,
      readOnly: this.readOnlyValue,
      rating: this.ratingValue
    });

    if (this.hasInputTarget) {
      this.$element.on('rateyo.set', (e, data) => {
        $(this.inputTarget).val(data.rating);
      });
    }
  }

  disconnect() {
    this.$rateYo.rateYo('destroy');
  }
}