import { Controller } from "@hotwired/stimulus";
import Rails from 'rails-ujs';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';
import 'select2/dist/js/i18n/fr';


export default class extends Controller {
  static values = { 
    submitFormOnChange: { type: Boolean, default: false },
    tagsOpt: { type: Boolean, default: false }
  }

  connect() {
    this.$select = $(this.element);
    if (this.element.multiple) {
      this.$select.select2({
        language: 'fr',
        width: "100%",
        tags: this.tagsOptValue
      })
    } else {
      let text = this.$select.find('option:first').text();
      $(this.$select).select2({
        allowClear: true,
        placeholder: { id: "", text: text },
        language: 'fr',
        width: "100%"
      })
    }
    if (this.submitFormOnChangeValue) {
      this.$select.on("select2:select", (e) => {
        Rails.fire(e.target.closest('form'), 'submit');
      })
      this.$select.on("select2:clear", (e) => {
        Rails.fire(e.target.closest('form'), 'submit');
      })
    }
  }

  disconnect() {
    this.$select.select2('destroy');
  }
}