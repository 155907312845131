import { Controller } from "@hotwired/stimulus";
import ClipboardWithTooltip from "../clipboard_with_tooltip";


export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardWithTooltip(this.element);
  };

  disconnect() {
    this.clipboard.destroy();
  }
}