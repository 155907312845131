import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['elementToDisable']

  disable(event) {
    if (!event.target.checked) {
      this.elementToDisableTarget.disabled = true;
    }
  }
}