import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'elementToEnable']

  enable(event) {
    if (event.target.checked) {
      this.elementToEnableTarget.disabled = false;
    }
  }
}