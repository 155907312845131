import { Controller } from "@hotwired/stimulus";

const navClass = ".NavMenu-list";

export default class extends Controller {
  static targets = ['menu', 'triggerOpen', 'triggerClose']; 

  connect() {
    this.isOpen = false;
    this.triggerOpenTarget.addEventListener('click', this.open.bind(this));
    this.triggerCloseTarget.addEventListener('click', this.close.bind(this));
    document.body.addEventListener('click', this.handleClose.bind(this));
    this.nav = this.menuTarget.querySelector(navClass);
  }

  handleClose(ev) {
    const {target: $target} = ev;
    if (this.isOpen
      && !($target.parentElement == this.triggerOpenTarget)
      && !$target.classList.contains('Hamburger')
      && !this.triggerCloseTarget.contains($target)
      && !$target.classList.contains(navClass.substring(1))
      && !this.nav.contains($target)) {
      this.close();
    }
  }

  open() {
    this.menuTarget.classList.add('is-open');
    this.isOpen = true;
  }

  close() {
    this.menuTarget.classList.remove('is-open');
    this.isOpen = false;
  }

  disconnect() {
    this.triggerOpenTarget.removeEventListener('click', this.open.bind(this));
    this.triggerCloseTarget.removeEventListener('click', this.close.bind(this));
    document.body.removeEventListener('click', this.handleClose.bind(this));
  }
}