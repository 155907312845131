import { Controller } from "@hotwired/stimulus";
import { tns } from "tiny-slider/src/tiny-slider.module.js"

export default class extends Controller {
  static targets = [ "controlsContainer", "container" ];

  connect() {
    this.slider = tns({
      container: this.containerTarget,
      controlsContainer: this.controlsContainerTarget,
      nav: false,
      autoplay: false,
      mouseDrag: true,
      speed: 400,
      gutter: 30,
      responsive: {
        0: {
          items: 1,
          slideBy: 1,
        },
        768: {
          items: 2,
          slideBy: 2,
        },
        992: {
          items: 3,
          slideBy: 3,
        }
      }
    });
  }

  disconnect() {
    this.slider.destroy();
  }
}