import 'cookieconsent/build/cookieconsent.min.js';
import 'cookieconsent/build/cookieconsent.min.css';
import { initializeGoogleAnalytics } from './googleAnalytics';



document.addEventListener("turbolinks:load", function(event) {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {"background": "#39A3B0", "text": "#ffffff"},
      "button": {"background": "#EB5C00", "text": "#ffffff"}
    },
    "theme": "classic",
    "content": {
      "message": "Linksium Formation utilise des cookies pour vous offrir une expérience utilisateur de qualité. En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies dans les conditions prévues par notre politique de confidentialité.",
      "dismiss": "J'accepte",
      "link": "En savoir plus",
      "href": "/politique-de-confidentialite"
    },
    onInitialise: function (status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type === 'info' && didConsent) {
        initializeGoogleAnalytics();
      }
    },
    onStatusChange: function (status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type === 'info' && didConsent) {
        initializeGoogleAnalytics();
      }
    },
  });
})